<script setup lang="ts">
import type { IBreadcrumbsData } from '@/types/types'
import { BreadcrumbsRoutes, BREAKPOINTS } from '@/types/constants'

const props = defineProps({
  isInBanner: {
    type: Boolean,
    default: false,
  },
})

const breadcrumb = computed<IBreadcrumbsData>(() => BreadcrumbsRoutes[useRoute().name as string])

const breakpoints = useBreakpoints(BREAKPOINTS)

const breadcrumbs = computed(() => breakpoints.isGreater('lg') ? breadcrumb?.value?.crumbs : breadcrumb?.value?.crumbs.slice(-2))

const home = ref({
  icon: 'mdi:home',
  redirectTo: '/',
})

const showBreadcrumbs = computed(() => {
  if (!props.isInBanner && breadcrumb.value?.banner)
    return false

  return true
})

// eslint-disable-next-line ts/no-unsafe-function-type
function redirect(redirectTo: string | Function) {
  if (!redirectTo)
    return

  if (typeof redirectTo === 'string')
    return navigateTo(redirectTo)

  return redirectTo()
}
</script>

<template>
  <div v-if="showBreadcrumbs">
    <PrimeBreadcrumb
      v-if="breadcrumb?.crumbs?.length"
      :key="$route.name"
      :home="home"
      :model="breadcrumbs"
      class="py-3 px-4 lg:!px-0"
      :class="isInBanner ? '!bg-transparent' : ''"
    >
      <template #item="{ item }">
        <span
          v-if="item.index === breadcrumb?.crumbs?.length - 1"
          class="font-semibold text-xs lg:text-sm"
          :class="isInBanner ? 'text-white' : 'text-secondary-300'"
        >
          <span>{{ item.label }}</span>
        </span>

        <button
          v-else
          :to="item.route"
          class="flex items-center"
          :class="isInBanner ? 'text-white' : 'text-neutral-200'"
          @click="() => redirect(item.redirectTo)"
        >
          <Icon v-if="item.icon" :name="item.icon" class="size-4" />
          <span v-if="item.label" class="text-xs lg:text-sm">{{ item.label }}</span>
        </button>
      </template>
    </PrimeBreadcrumb>

    <h1
      v-if="breadcrumb?.showTitle"
      class="px-4 lg:px-0 mt-6 lg:mt-4 font-sora text-xl text-primary-400"
    >
      {{ breadcrumb?.crumbs[breadcrumb?.crumbs?.length - 1].label }}
    </h1>
  </div>
</template>
